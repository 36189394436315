.section {
  width: 100%;
  display: inline-block;
  padding-top: 3rem;
  padding-bottom: 3rem;

  @media screen and (min-width: $mobile) and (max-width: $tablet - 1px) {
    padding: 2.25rem 1.25rem;
  }

  @media screen and (min-width: $tablet) {
    padding-left: 1.25rem; /* 20px */
    padding-right: 1.25rem; /* 20px */
  }

  @media screen and (min-width: $desktop) {
    padding-left: 1.875rem; /* 30px */
    padding-right: 1.875rem; /* 30px */
  }
}

.section--login {
  padding-top: 0rem;
}

.section__inner {
  width: 100%;
  max-width: $project-wrapper;
  margin-left: auto;
  margin-right: auto;
}

.section--intro p,
.section--brand p,
.section--design p,
.section--devices p,
.section--community p,
.section--text p {
  @media screen and (min-width: $tablet) {
    padding-top: 2rem;
    max-width: ($project-body-width - 30px);
  }

  @media screen and (min-width: $desktop) {
    max-width: $project-body-width;
  }
}

.section--brand p,
.section--design p {
  float: right;
}

.section--brand .as-section-header {
  float: left;

  @media screen and (min-width: $mobile) and (max-width: $tablet - 1px) {
    padding-right: 1.25rem;
  }
}

.section--devices .as-section-header {
  float: right;
}

.section--social {
  background: #fff;
  margin-top: 2rem;
  text-align: center;
}

.section--text {
  width: 100%;
  display: inline-block;
}

.section--text p {
  float: left;
}

.section--text .as-section-header {
  float: right;

  @media screen and (min-width: $mobile) and (max-width: $tablet - 1px) {
    padding-left: 1.25rem;
  }
}

.section--scene span {
  float: right;
  font-family: $f-sans-serif;
  font-style: normal;
}

.quote {
  position: relative;
  width: 100%;
  margin-bottom: 5rem;

  @media screen and (min-width: $tablet) {
    display: flex;
  }
}

.quote .quote__image {


  @media screen and (min-width: $tablet) {
    min-width: 130px;
    margin-right: 1.875rem;
  }
}

.quote--right {
  display: table;

  .quote__image {
    display: table-header-group;
  }

  .quote__inner {
    display: table-footer-group;
  }

  @media screen and (min-width: $tablet) {
    display: flex;
  }
}


.quote--right .quote__image {

  @media screen and (min-width: $tablet) {
    margin-left: 1.875rem;
    margin-right: 0;
  }
}

.quote__image img {
  margin-left: auto;
  margin-right: auto;
  max-width: 130px;
  border-radius: 50%;
  margin-bottom: 1rem;

  @media screen and (min-width: $tablet) {
  }
}

.quote__inner {
  text-align: left;
}
