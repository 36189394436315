*, *:before, *:after {
  margin: 0;
  padding: 0;
  border: 0;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
article, aside, details, figcaption, figure,
footer, header, hgroup, main, nav, section, summary {
  display: block;
}