.wrapper {
  width: 100%;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}

.wrapper--section {
  @media screen and (min-width: $mobile) {
    padding-left: 1.25rem; /* 20px */
    padding-right: 1.25rem; /* 20px */
  }

  @media screen and (min-width: $desktop) {
    padding-left: 1.875rem; /* 30px */
    padding-right: 1.875rem; /* 30px */
  }
}
