$mobile: 320px;
$tablet: 768px;
$desktop: 1024px;

$mobile-retina: 40em; /* 640px */
$tablet-portrait: 50em; /* 800px */

$color-background: #EEEEEE;
$color-white: #fff;
$color-black: #2B2B2B;
$color-main: #4FB0FF;
$color-copy-grey: #636363;
$color-grey-dark: #242830;
$color-grey-light: #EEEEEE;
$color-button: #0F3F9A;

// Font Stack

$f-sans-serif: 'HalisR-Bold', Helvetica, sans-serif;
$f-serif: 'ff-tisa-web-pro', Georgia, Times, serif;

// Font Sizes

$fs-nav: 1rem; /* 16px */
$fs-headline-project: 3.25rem; /* 52px */
$fs-headline-section: 2rem; /* 32px */
$fs-meta: 1rem; /* 16px */
$fs-body-copy: 1.25rem; /* 20px */

// Cases

$project-wrapper: 784px;
$project-body-width: 450px;

// Boge Variables

$color-boge-red: #DA020D;

//DEW Variables

$color-blue-dew: #0564AD;

//Autostadt Variables

$color-green-as: #95CC3E;

//Uniper Variables

$color-blue-uni: #0082BD;

//About Variables

$color-about: #FF5350;

//Telekom Variables

$color-magenta: #E20074;
