.meta {
  margin: 2rem 0 1.5rem;
  font-family: $f-sans-serif;
}

.meta li {
  display: block;
  list-style: none;
  line-height: 1.8;
  color: $color-black;
}

.meta li p {
  display: inline-block;
}
