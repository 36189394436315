.projectgrid {
  background-color: $color-white;
}

.projects {
  position: relative;

  // Project Header
  .as-project-header:after {
    visibility: hidden;
  }

  // Project Teaser

  .teaser-grid {
    display: flex;
    flex-flow: wrap;
    list-style: none;
  }

  .teaser-grid__item {
    display: flex;
    align-items: center;
    padding: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 1rem;
    border-bottom: 8px solid $color-white;
    background: #eeeeee;
    position: relative;
    overflow: hidden;
  }

  .teaser-grid__item:nth-child(1),
  .teaser-grid__item:nth-child(4) {
    @media screen and (min-width: $mobile) {
      width: 100%;
    }
    @media screen and (min-width: $tablet) {
      width: calc((100% / 3 * 2) - 1rem); // 2/3 column - 1rem margin
    }
  }

  .teaser-grid__item:nth-child(2),
  .teaser-grid__item:nth-child(3) {
    @media screen and (min-width: $mobile) {
      width: 100%;
    }
    @media screen and (min-width: $tablet) {
      width: calc((100% / 3 * 1) - 1rem); // 1/3 column - 1rem margin
    }
  }

  .teaser-grid__item--project-boge {
    border-bottom-color: #F0001C;

    .teaser-grid__item__about {
      background: rgba(240,0,28,0.9);
    }
  }

  .teaser-grid__item--project-dew {
    border-bottom-color: $color-blue-dew;

    .teaser-grid__item__about {
      background: rgba(5,100,173,0.9);
    }
  }

  .teaser-grid__item--project-uniper {
    border-bottom-color: #0082BD;

    .teaser-grid__item__about {
      background: rgba(0,130,189,0.9);
    }

    img {
      margin-left: 1rem; // Get rid of general image padding
    }
  }

  .teaser-grid__item--project-autostadt {
    border-bottom-color: #95CC3E;

    .teaser-grid__item__about {
      background: rgba(149,204,62,0.9);
    }
  }

  .teaser-grid__item--project-telekom {
    border-bottom-color: $color-magenta;

    .teaser-grid__item__about {
      background: rgba(226,0,116,0.9);
    }
  }

  .teaser-grid__item__about {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 1rem;
    transition: ease-in-out transform 0.2s;

    @media screen and (min-width: $mobile) {
      height: 100px;
    }

    @media screen and (min-width: $tablet) {
      height: 100px;
    }

    @media screen and (min-width: $desktop) {
      transform: translateY(170px);
      height: 170px;
    }
  }

  .teaser-grid__item__about h1,
  .teaser-grid__item__about p {
    color: $color-white;
  }

  .teaser-grid__item__about h1 {
    margin-bottom: 0.5rem;
  }

  .teaser-grid__item__about h1 span {
    box-shadow: inset $color-white 0 -5px 0;
    padding-bottom: 5px;
  }

  .teaser-grid__item:hover .teaser-grid__item__about {
    transform: translateY(0px);
  }

}
