.form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto 3rem auto;
}

.form p {
  margin-bottom: 2rem;
}

.label {
  font-size: 0.875rem;
}

.input {
  display: block;
  width: 100%;
  padding: 0.5rem;
  font-size: 1.25rem;
  font-family: $f-serif;
  margin-bottom: 1rem;
  border: 2px solid rgba(0,0,0,0.2);
  border-radius: 2px;
}

.input:focus {
  border-color: $color-button;
}

textarea, input {
  outline: none;
}
