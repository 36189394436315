.boge {
  position: relative;

  // Meta Link

  .meta li p {
    display: inline-block;
  }

  .meta li p a {
    font-size: 1rem;
    color: $color-boge-red;
  }

  .as-section-header {
    color: $color-boge-red;

    &:after {
      border-color: $color-boge-red;
    }
  }

  // Intro

  .section--intro {
    background: $color-black url('../images/boge/BOGE_BG_Intro@2x.jpg');
    background-size: cover;
    color: $color-white;
    margin-bottom: 38%;
  }

  .section--intro .as-section-header {
    color: $color-white;

    @media screen and (min-width: $tablet) {
      float: right;
    }
  }

  .section--intro .as-section-header::after {
    border-color: $color-white;
  }

  .section--intro figure {
    margin-top: 2rem;
    margin-bottom: -50%;

    @media screen and (min-width: $tablet) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }

    @media screen and (min-width: $desktop) {
      padding-left: 5rem;
      padding-right: 5rem;
    }
  }

  .section--brand .as-section-header,
  .section--devices .as-section-header {
    color: $color-boge-red;
  }

  .section--brand .as-section-header::after,
  .section--devices .as-section-header::after {
    border-color: $color-boge-red;
  }

  .section--brand figure {
    margin-top: 2rem;
    display: inline-block;

    @media screen and (min-width: $tablet) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }

    @media screen and (min-width: $desktop) {
      padding-left: 5rem;
      padding-right: 5rem;
    }
  }

  .section--devices figure {
    margin-top: 2rem;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .section--timeline {
    padding-left: 0;
    padding-right: 0;
  }

  .timeline-wrapper {
    width: 100%;
    height: auto;
    overflow: scroll;
  }

  .timeline-wrapper figure {
    width: 3000px;
    overflow: hidden;
  }

  .section--devices {
    padding-bottom: 0;
  }

}
