.dew {
  position: relative;
  padding-bottom: 2rem;

  // Project Header

  .section {
    padding-bottom: 0;
  }

  .as-project-header {
    color: $color-blue-dew;
  }

  .as-project-header:after {
    border-bottom-color: $color-blue-dew;
  }

  // Intro

  .section--intro .as-section-header {
    color: $color-blue-dew;

    @media screen and (min-width: $tablet) {
      float: right;
    }
  }

  .section--intro .as-section-header::after {
    border-color: $color-blue-dew;
  }

  .section--intro figure {
    margin-top: 2rem;
    padding-bottom: 2.5rem;

    @media screen and (min-width: $tablet) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }

    @media screen and (min-width: $desktop) {
      padding-left: 5rem;
      padding-right: 5rem;
    }
  }

  // Tab Modul
  .section--tab {
    background: $color-blue-dew;
    background-size: cover;
    color: $color-white;
    padding-bottom: 2rem;
  }

  .section--tab figure {

  }


  .section--brand .as-section-header,
  .section--community .as-section-header {
    color: $color-blue-dew;
  }

  .section--brand .as-section-header::after,
  .section--community .as-section-header::after {
    border-color: $color-blue-dew;
  }

  .section--brand figure {
    margin-top: 4rem;
    display: inline-block;

    @media screen and (min-width: $tablet) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }

    @media screen and (min-width: $desktop) {
      padding-left: 5rem;
      padding-right: 5rem;
    }

  }

  .section--browser figure {
    margin-top: 2rem;
    display: inline-block;

    @media screen and (min-width: $mobile) {
      margin-top: 1rem;
      margin-bottom: 1.5rem;
    }

    @media screen and (min-width: $tablet) {
      margin-bottom: 4rem;
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }

    @media screen and (min-width: $desktop) {
      padding-left: 5rem;
      padding-right: 5rem;
    }
  }


  .section--community .as-section-header {

    @media screen and (min-width: $tablet) {
      float: right;
    }
  }

  .section--community figure {
    margin-top: 2rem;

    @media screen and (min-width: $tablet) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }

    @media screen and (min-width: $desktop) {
      padding-left: 5rem;
      padding-right: 5rem;
    }
  }

  .nextprev {
    margin-top: 2rem;
  }
  .nextprev a {
    color: $color-blue-dew;
    border: 0;
  }
}
