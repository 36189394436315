.autostadt {
  position: relative;

  // Project Header

  .section {
    padding-bottom: 3rem;
  }

  .as-project-header {
    color: $color-green-as;
  }

  .as-project-header:after {
    border-bottom-color: $color-green-as;
  }

  // Cover - serves as a background image

  .section__cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 550px;
    background: $color-black url('../images/autostadt/Autostadt_BG_Intro@2x.jpg');
    background-size: cover;
    z-index: 0;

    @media screen and (min-width: $mobile) {
      height: 480px;
    }

    @media screen and (min-width: $tablet) {
      height: 550px;
    }

  }

  .section__inner,
  figure {
    position: relative;
    z-index: 1;
  }


  // Intro

  .section--intro {
    position: relative;
    color: $color-white;
  }

  .section--intro .as-section-header {
    color: $color-white;

    @media screen and (min-width: $tablet) {
      float: right;
    }
  }

  .section--intro .as-section-header::after {
    border-color: $color-white;
  }

  .section--intro figure {
    margin-top: 2rem;

    @media screen and (min-width: $tablet) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }

    @media screen and (min-width: $desktop) {
      padding-left: 5rem;
      padding-right: 5rem;
    }
  }

  .section--brand .as-section-header,
  .section--devices .as-section-header {
    color: $color-green-as;
  }

  .section--brand .as-section-header::after,
  .section--devices .as-section-header::after {
    border-color: $color-green-as;
  }

  .section--brand {
    padding-bottom: 2rem;
  }

  .section--brand figure {
    margin-top: 2rem;
    display: inline-block;

    @media screen and (min-width: $tablet) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }

    @media screen and (min-width: $desktop) {
      padding-left: 5rem;
      padding-right: 5rem;
    }
  }

  .section--devices figure {
    margin-top: 2rem;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .section--scene {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-bottom: 4rem;
  }


  .section--scene figure {
    margin: 0 auto;
    max-width: 700px;
  }

  .section--devices {
    padding-bottom: 0;
  }

  .nextprev a {
    color: $color-green-as;
    border: 0;
  }

}
