.icon {
  display: inline-block;
  margin-right: 0.5rem;
}

.icon--email {
  background: url('../images/icn_mail.svg') no-repeat;
  background-size: 24px auto;
  width: 24px;
  height: 22px;
  vertical-align: middle;
  margin-left: -18px;
}
