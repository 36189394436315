.nextprev {
  width: 100%;
  padding: 0.75rem 1.25rem;
  position: absolute;
  bottom: 0;
  font-family: $f-sans-serif;
}

.nextprev a {
  color: $color-boge-red;
  border: 0;
}

.nextprev .prev {
  float: left;
}

.nextprev .next {
  float: right;
}
