a {
  text-decoration: none;
  transition: color .3s, background .3s, border .3s;
}

a:hover {
  color: #fff;

}

a:visited {
  text-decoration: none;
}
