.button {
  font-family: $f-sans-serif;
  display: inline-block;
  padding: 0.6rem 0.8rem;
  font-size: 1rem;
  background: $color-button;
  color: #fff;
  text-transform: uppercase;
  border-radius: 2px;
  box-shadow: 0px 3px 0px 0px rgba(96,149,227,0.50);
  border-radius: 2px;

  &:hover {
    background: shade($color-button,30%);
  }
}

input.button {
  font-family: $f-sans-serif;
  cursor: pointer;
  padding-left: 2rem;
  padding-right: 2rem;
}

.button--email {
  padding-left: 6rem;
  padding-right: 6rem;
}
