.menu a {
  font-family: $f-sans-serif;
  font-size: 1em;
  line-height: 1.5em;
  text-transform: uppercase;
  display: block;
  color: rgba(43, 43, 43, 1);
}
.menu a:hover {
  color: rgba(43, 43, 43, 0.6);
}
.menu .active {
  color: rgba(43, 43, 43, 0.6);
}
.menu li {
  list-style: none;
}
.menu > li {
  position: relative;
}
.menu > li > a {
  padding: 0.35em 0;
  border-top: 1px solid #ddd;
}

@media screen and (min-width: $mobile-retina) {
  .logo {
    float: left;
  }
  .menu {
    float: right;
    margin-right: -1em;
  }
  .menu > li {
    float: left;
  }
  .menu > li > a {
    padding: .35em 1em;
    border: 0;
  }
}

.nav-collapse ul {
  margin: 0;
  padding: 0;
  display: block;
  list-style: none;
}

.nav-collapse li {
  display: block;
}

.js .nav-collapse {
  clip: rect(0 0 0 0);
  max-height: 0;
  position: absolute;
  display: block;
  overflow: hidden;
  zoom: 1;
}

.nav-collapse.opened {
  max-height: 9999px;
}

.nav-toggle {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  font-family: $f-sans-serif;
  color: $color-black;
  position: absolute;
  top: 1rem;
  right: 1.25rem;
  padding: 0.35rem 0;
}

@media screen and (min-width: $tablet) {
  .js .nav-collapse {
    position: relative;
  }
  .js .nav-collapse.closed {
    max-height: none;
  }
  .nav-toggle {
    display: none;
  }
}
