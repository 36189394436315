.gallery-cell {
  width: 100%;
  height: auto;
}

/* position dots up a bit */
.flickity-page-dots {
  bottom: -15px;
}

/* dots are lines */
.flickity-page-dots .dot {
  height: 14px;
  width: 33.33%;
  margin: 0;
  border-radius: 0;
  opacity: 1;
  background: #95CC3E;
}

.flickity-page-dots .dot:hover {
  opacity: 0.8;
}

.flickity-page-dots .dot:first-child,
.flickity-page-dots .dot:last-child {
  background: #0082BD;
}

// Prev Next Buttons

.flickity-prev-next-button {
  width: 100px;
  height: 100px;
  background: transparent;
}
.flickity-prev-next-button:hover {
  background: transparent;
}

// arrow color

.flickity-prev-next-button .arrow {
  fill: white;
}

.flickity-prev-next-button.no-svg {
  color: white;
}

.flickity-prev-next-button:disabled {
  display: none;
}

// show buttons on gallery mouseover - just on desktop

@media screen and (min-width: $desktop) {
  .flickity-enabled .flickity-prev-next-button {
    opacity: 0;
    transition: ease-in-out all 0.2s;
  }

  .flickity-enabled:hover .flickity-prev-next-button {
    opacity: 1;
  }
}
