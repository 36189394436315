.social-links {
  background: transparent;
  font-family: $f-sans-serif;
  font-size: 1em;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 2rem;
}

.social-links li {
  display: inline-block;
  padding: 1rem 2rem;
}

.social-links a {
  color: rgba(43, 43, 43, 1);

  &:hover {
    color: rgba(43, 43, 43, 0.6);
  }

  &:active {
    color: rgba(43, 43, 43, 0.6);
  }
}




.email {
  color: #6ABCB4;

  &:hover {
    background: #6ABCB4;
    color: #fff;
  }
}
