.about {
  position: relative;

  // Project Header

  .section {
    padding-bottom: 3rem;
  }

  .as-project-header {
    color: $color-about;
  }

  .as-project-header:after {
    content: '';
    border-bottom: 8px solid $color-about;
    display: block;
    width: 24.2%;
  }

  // Cover - serves as a background image

  .section__cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 620px;
    background: $color-black url('../images/about/About_Intro@2x.jpg');
    background-size: cover;
    z-index: 0;

    @media screen and (min-width: $mobile) {
      height: 560px;
    }

    @media screen and (min-width: $tablet) {
      height: 620px;
    }

  }

  .section__inner,
  .section__content {
    position: relative;
  }

  .section__inner p {
    color: $color-black;
    font-size: 1.125rem;

    @media screen and (min-width: $desktop) {
      margin-left: -11%;

    }
  }

  // Intro

  .section--intro {
    position: relative;
    color: $color-copy-grey;
  }

  .section--intro .as-section-header {
    color: $color-about;

    @media screen and (min-width: $tablet) {
      float: right;
    }
  }
  .section--intro .as-section-header::after {
    border-color: $color-about;
  }

  // Content

  .section__content {
    background-color: $color-white;
    display: inline-block;
    padding-top: 3rem;
    margin-top: 8rem;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;

    @media screen and (min-width: $tablet) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }

    @media screen and (min-width: $mobile) {
      width: 98%;
      margin-left: 1%;
      margin-right: 1%;
    }
  }

  //Experience
  .resume {
    color: $color-black;

    @media screen and (min-width: $mobile) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    @media screen and (min-width: $tablet) {
      float: left;
      width: 50%;
    }
  }

  .resume .as-section-header {
    color: $color-about;
    display: block;
    float: none;
    margin-bottom: 2rem;
  }

  .resume ul {
    list-style: none;
  }

  .resume li {
    margin-bottom: 2.250rem;

    @media screen and (min-width: $mobile) {
      line-height: 1.5rem;
    }

    @media screen and (min-width: $tablet) {
      line-height: 1.8rem;
    }

  }

  .resume strong {
    font-family: $f-sans-serif;
  }

  //Button

  .button  {
    border: 4px solid $color-about;
    font-family: $f-sans-serif;
    font-weight: 900;
    font-size: 0.938rem;
    color: $color-about;
    padding: 10px 20px;
  }

  .button:hover {
    background-color: $color-about;
    color: $color-white;
    transition: color 0.3s, background 0.3s;
  }

}
