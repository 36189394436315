.nav-tabs {
  width: 100%;
  margin-bottom: 1rem;
}

.nav-tabs__item {
  font-family: $f-sans-serif;
  list-style: none;
}

.nav-tabs__item a {
  display: block;
  position: relative;
  padding: 0 1rem 0 4.2rem;
  margin-bottom: 0.625rem; /* 10px */
  height: 50px;
  line-height: 50px;
  color: $color-white;
  background-repeat: no-repeat;
  background-position: 10px center;

  @media screen and (min-width: $mobile) {
    width: 100%
  }

  @media screen and (min-width: $tablet) {
    width: 86%;
  }
}

.nav-tabs__item--one a {
  background-image: url('../images/dew/icn_regional_default.svg');
  background-position: 14px center;
}

.nav-tabs__item--one.active a {
  background-image: url('../images/dew/icn_regional_active.svg');
}

.nav-tabs__item--two a {
  background-image: url('../images/dew/icn_ueberregional_default.svg');
  background-position: 14px center;
}

.nav-tabs__item--two.active a {
  background-image: url('../images/dew/icn_ueberregional_active.svg');
}

.nav-tabs__item--three a {
  background-image: url('../images/dew/icn_portal_default.svg');
}

.nav-tabs__item--three.active a {
  background-image: url('../images/dew/icn_portal_active.svg');
}

.nav-tabs__item.active a {
  color: $color-blue-dew;
  background-color: $color-white;
  background-repeat: no-repeat;
}

.nav-tabs__item.active a::after {
  @media screen and (min-width: $tablet) {
    content: '';
    position: absolute;
    right: -25px;
  	width: 0;
  	height: 0;
  	border-top: 25px solid transparent;
  	border-bottom: 25px solid transparent;
  	border-left: 25px solid $color-white;
  }
}
