.tab-content {
  width: 100%;
}

.tab-pane {
  display: none;
}

.tab-pane.active {
  display: block;
}
