/* Contact boxes*/
.social-links {
  width: 100%;
  display: inline-block;
  background: #EEEEEE;
}

.social-list {
  width: 100%;
  list-style: none;
}

.social-list__item {
  width: calc(100% / 3);
  float: left;

  &:first-child {
    padding-right: 20px;
  }

  &:last-child {
    padding-left: 20px;
  }
}

.social-box {
  height: 160px;
  background: #FFFFFF;
  box-shadow: 0px 4px 0px 0px #E7E7E7;
  padding: 20px 15px 0 15px;
  transition: all 500ms ease-in-out;
  text-align: center;
}

.social-box:hover {
  box-shadow: 0px 4px 0px 0px $color-main;
  transform: translateY(-15px);
}

.social-icon {
  position: relative;
  left: 45%;
  margin: 8px 0 12px 0;
}

.social-box h3 {
  margin-bottom: 20px;
}

.social-box p {
  color:$color-copy-grey;
  text-decoration: none;
}

/* Footer */

.footer {
  padding: 1.25rem;
  font-family: $f-sans-serif;
}

.footer p {
  font-size: 0.875rem;
}

.copyright {
  float: left;
}

.legal {
  float: right;
}

a.legal ,
a.legal :link,
a.legal :hover,
a.legal :visited {
  color: $color-black;
  font-size: 0.875rem;
  line-height: 1.5;
}
