@import url(http://fonts.googleapis.com/css?family=Lato:400,700,900|PT+Serif:400,700);

h1 {
  font-family: $f-sans-serif;
  font-size: 2rem;
  line-height: 1.25;
  color: $color-black;
}

.as-project-header {

  @media screen and (min-width: $mobile) {
    font-size: 2.5rem;
  }

  @media screen and (min-width: $tablet) {
    font-size: $fs-headline-project;
  }
}

.as-project-header:after {
  content: '';
  border-bottom: 7px solid $color-black;
  margin: 0.75rem 0 1rem;
  display: block;
  width: 15%;
}

.as-section-header {
  font-size: $fs-headline-section;
  margin-bottom: 1.125rem; /* 18px */
  max-width: 280px;
  display: inline-block;
}

.as-section-header:after {
  content: '';
  border-bottom: 5px solid $color-black;
  margin: 0.75rem 0 1rem;
  display: block;
  width: 52px;
}

.as-tab-header {
  margin-bottom: 1.125rem; /* 18px */
  max-width: 280px;
  display: inline-block;
}


h2 {
  font-family: $f-sans-serif;
  font-size: 2rem;
  line-height: 1.25;
  text-align: center;
  margin-bottom: 1rem;
  color: $color-grey-dark;
}

h3 {
  font-family: $f-sans-serif;
  font-size: 1.5rem;
  line-height: 1.813;
  margin-bottom: 2;
  color: $color-white;
}

h4 {
  font-family: $f-sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
  margin-bottom: .5rem;
}

h1 a, h2 a, h3 a, h4 a {
  color: inherit;
  border: 0 !important;
}

p {
  line-height: 1.5;
  font-weight: 400;
  margin-bottom: 0.875rem;

  @media screen and (min-width: $mobile) {
    font-size: 1rem;
  }

  @media screen and (min-width: $tablet) {
    font-size: 1.25rem;
  }
}

p strong {
  font-weight: 700;
}
