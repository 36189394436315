.uniper {
  position: relative;

  // Project Header

  .section {
    padding-bottom: 3rem;
  }

  .as-project-header {

    color: $color-blue-uni;
  }

  .as-project-header:after {
    border-bottom-color: $color-blue-uni;
  }

  // Cover - serves as a background image

  .section__cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 550px;
    background: $color-black url('../images/uniper/Uniper_BG_Intro@2x.jpg');
    background-size: cover;
    z-index: 0;

    @media screen and (min-width: $mobile) {
      height: 480px;
    }

    @media screen and (min-width: $tablet) {
      height: 550px;
    }

  }

  .section__inner,
  figure {
    position: relative;
    z-index: 1;
  }


  // Intro

  .section--intro {
    position: relative;
    color: $color-white;
  }

  .section--intro .as-section-header {
    color: $color-white;

    @media screen and (min-width: $tablet) {
      float: right;
    }
  }

  .section--intro .as-section-header::after {
    border-color: $color-white;
  }

  .section--intro figure {
    margin-top: 2rem;

    @media screen and (min-width: $tablet) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }

    @media screen and (min-width: $desktop) {
      padding-left: 5rem;
      padding-right: 5rem;
    }
  }

  .section--brand .as-section-header,
  .section--design .as-section-header,
  .section--devices .as-section-header {
    color: $color-blue-uni;
  }

  .section--brand .as-section-header::after,
  .section--design .as-section-header::after,
  .section--devices .as-section-header::after {
    border-color: $color-blue-uni;
  }

  .section--brand, {
    padding-bottom: 2rem;
  }

  .section--brand figure {
    margin-top: 2rem;
    display: inline-block;

    @media screen and (min-width: $tablet) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }

    @media screen and (min-width: $desktop) {
      padding-left: 5rem;
      padding-right: 5rem;
    }
  }

  .section--devices figure {
    margin-top: 2rem;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .section--scene {
    padding-bottom: 4rem;
  }

  .section--devices {
    padding-bottom: 0;
  }

  .nextprev a {
    color: $color-blue-uni;
    border: 0;
  }

  // 3D Modul

  .section--dimension {
    color: $color-white;
    padding-bottom: 2rem;
  }

  .screen-persp {
    position: relative;
    transition: transform all 0.8s;
  }

  .screen-persp:nth-child(2),
  .screen-persp:nth-child(3) {
    @media screen and (min-width: $mobile) {
      margin-top: -6rem;
    }

    @media screen and (min-width: $tablet) {
      margin-top: -10rem;
    }

    @media screen and (min-width: $desktop) {
      margin-top: -14rem;
    }
  }

  .screen-persp--one {
    z-index: 10;
  }

  .screen-persp--two {
    z-index: 5;
  }

  .screen-persp--three {
    z-index: 0;
  }

}
