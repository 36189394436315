html {
  font-family: $f-serif;
  font-weight: 400;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0 auto;
  font-size: 1em;
  line-height: 1.5em;
  background: $color-background;
  color: $color-black;
}

.row {
  width: 100%;
  display: inline-block;
}

.col {
  float: left;
  @media screen and (min-width: $mobile) {
    display: block;
  }

  @media screen and (min-width: $tablet) {
    display: inline-block;
  }
}

.col--one-third {
  @media screen and (min-width: $mobile) {
    width: 100%;
  }

  @media screen and (min-width: $tablet) {
    width: 40%;
  }

  @media screen and (min-width: $desktop) {
    width: 33.33%;
  }
}

.col--two-third {
  @media screen and (min-width: $mobile) {
    width: 100%;
  }

  @media screen and (min-width: $tablet) {
    width: 60%;
  }

  @media screen and (min-width: $desktop) {
    width: 66.66%;
  }
}
