.row {
  width: 100%;
  display: inline-block;
}

.row--center {
  text-align: center;
  margin-top: 1rem;

  @media screen and (min-width: $mobile) {
    margin-bottom: 2.5rem;
  }

  @media screen and (min-width: $tablet) {
    margin-bottom: 4rem;
  }
}
