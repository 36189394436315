.home {
  position: relative;
  background-color: #EEEEEE;
  min-height: 100vh;

  .header,
  .footer {
    background: transparent;
  }

  // Project Header

  .section {
    padding-bottom: 3rem;
  }

  .as-project-header {
    color: $color-about;
  }

  .as-project-header:after {
    content: '';
    border-bottom: 8px solid $color-about;
    display: block;
    width: 24.2%;
  }

  .extern {
    color: rgb(96,149,227);
  }

  .extern:hover {
    color: rgba(96,149,227,0.7);
  }
}

.home h1 {
  color: #0F3F9A;
  font-size: 3rem;
  text-shadow: rgba(255,255,255,1) 2px 2px 0;
  padding-top: 3rem;

  @media screen and (min-width: $mobile) {
    font-size: 2rem;
  }

  @media screen and (min-width: $tablet) {
    font-size: $fs-headline-project;
  }
}

.home h1 a {
  border-bottom: 2px solid;
}
