.logo {
  font-family: $f-sans-serif;
  font-size: $fs-nav;
  color: $color-black;
  padding: 0.35rem 0;
  display: block;
}

.logo:visited {
  color: $color-black;
}
